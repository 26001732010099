.selectFromInput {
  width: 100%;
  height: fit-content;
  position: relative;
  background-color: inherit;
}
.selectFromInput input {
  background-color: inherit;
  outline: none;
}

.selectFromInput .listContainer {
  position: absolute;
  width: 100%;
  z-index: 99;
  max-height: 360px;
  overflow-y: auto;
}

.header-class {
  @apply border h-10 rounded-lg text-base border-primary-300 text-primary-700 dark:text-primary-100;
}
.content-class {
  @apply custom-shadow text-base text-primary-700 dark:text-primary-100 bg-primary-50 dark:bg-primary-700;
}
