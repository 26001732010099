.navbar {
  @apply flex items-center flex-row custom-shadow px-2 h-14 text-primary-800 dark:text-primary-100 bg-white dark:bg-dark-light;
}

.navbar .custom-search {
  height: 38px;
  border-radius: 5px;
  background-color: inherit;
  @apply min-w-[220px] sm:w-72 border border-primary-200 dark:border-primary-700;
}
