@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h5 {
    @apply font-normal;
  }

  h4 {
    @apply text-xs font-medium;
  }

  h3 {
    @apply text-sm font-semibold;
  }

  h2 {
    @apply text-base font-bold;
  }
  tbody > tr {
    @apply border h-12 border-x-0 hover:bg-opacity-80 border-primary-300 dark:border-primary-700;
  }
  table > thead {
    @apply z-10 sticky top-0 shadow-xl bg-primary-100 dark:bg-primary-600;
  }
}
@layer components {
  .mini-card {
    width: 60px;
    height: 24px;
    border-radius: 4px;
    filter: drop-shadow(0px 3px 3.5px rgba(0, 0, 0, 0.16));
    display: flex;
    align-items: center;
    justify-content: center;
    @apply text-primary-700 bg-white;
  }
}
@layer utilities {
  .centerY {
    @apply flex items-center;
  }
  .centerX {
    @apply flex justify-center;
  }
  .centerXY {
    @apply flex justify-center items-center;
  }
  .custom-shadow {
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
  }
}
