@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 40px;
  height: 40px;
  border-width: 8px;
  border-top-width: 8px;
  border-style: solid;
  border-radius: 100%;
  animation: spinner 1.5s linear infinite;
  @apply border-t-primary-700 border-primary-200 dark:border-primary-500 dark:border-t-primary-700;
}
