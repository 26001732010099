.text-box {
  position: relative;
  background: 'inherit';
  background-color: inherit;
  border-radius: inherit;
}
.text-box::after {
  content: '';
  background-color: inherit;
  @apply w-5 h-5 block absolute top-2 transform  rotate-45;
}
