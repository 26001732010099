.table-container tr:nth-child(2n + 1) > td > span {
  background-color: #28a745;
}

.table-container tr:nth-child(2n) > td > span {
  background-color: #17a2b8;
}
.table-container tr:nth-child(3n) > td > span {
  background-color: #ffc107;
}
.table-container tr:nth-child(3n) > td > span {
  background-color: #dc3545;
}
.tower-sites th {
  padding: 0 10px;
}
.tower-sites td {
  padding: 0 10px;
}
