.filter-on-map .grid-item {
  @apply col-span-12  md:col-span-6 lg:col-span-4 xl:col-span-3;
}

.filter-on-map .custom-search {
  height: 38px;
  border-radius: 5px;
  background-color: inherit;
  @apply min-w-[220px] sm:w-72 border border-primary-200 dark:border-primary-700;
}
