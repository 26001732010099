.progress {
  display: block;
}

.progress .bar {
  width: 0;
  display: block;
  height: inherit;
  background-color: green;
  transition: all 1s ease-in-out;
  border: inherit;
  border-radius: inherit;
}
