.check-box {
  display: flex;
  align-items: center;
}

.check-box > input[type='checkbox'] {
  --webkit-appearance: none;
  appearance: none;
  position: relative;
  width: 14px;
  height: 14px;
  aspect-ratio: 1/1;
  background-color: lightgray;
  border-radius: 3px;
}
.check-box > input[type='checkbox']::after {
  content: '';
  width: inherit;
  height: inherit;
  display: inline-block;
  border: inherit;
  border-radius: inherit;
  background-color: inherit;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.check-box > input[type='checkbox']::before {
  content: '';
  width: 50%;
  height: 50%;
  display: inline-block;
  outline: 2px solid gray;
  scale: (0);
  background-color: green;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  z-index: 1;

  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

.check-box > input[type='checkbox']:checked::before {
  transform: translate(-50%, -50%) scale(1);
}
