.input-wrapper {
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.input-wrapper textarea {
  width: 100%;
  height: 100%;
  padding: 0 16px;
  border: 0;
  background-color: inherit;
  font-size: inherit;
  color: inherit;
}

.input-wrapper textarea::placeholder {
  opacity: 0.7;
  font-size: 14px;
}

.input-wrapper textarea:focus {
  outline: none;
}
