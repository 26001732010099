.drop-down-web {
  position: relative;
}

.drop-down-web > ul {
  @apply text-sm text-primary-500 font-medium cursor-pointer;
}
.drop-down-web:hover > ul {
  @apply text-primary-600;
}

.drop-down-web .body {
  position: absolute;
  left: -28px;
  visibility: collapse;
  height: 0;
  z-index: 100;
}
.drop-down-web .body .content {
  margin-top: 16px;
  width: max-content;
  display: flex;
  border-radius: 12px;
  overflow: hidden;
  background-color: white;
  -webkit-box-shadow: 0px 10px 70px rgb(0 0 0 / 15%);
  box-shadow: 0px 10px 70px rgb(0 0 0 / 15%);
}
.drop-down-web .body .content .group-wrapper {
  background: #fff;
  padding: 24px 46px;
}
.drop-down-web .body .content .subContent {
  background: #f8f8f8;
  padding: 24px 46px;
}
.drop-down-web:hover .body {
  visibility: visible;
  height: fit-content;
}

.drop-down-mobile svg {
  transition: all 0.3s linear;
}

.drop-down-mobile .expanded svg {
  transition: all 0.3s linear;
  transform: rotate(180deg);
}

.drop-down-mobile .body {
  position: absolute;
  visibility: collapse;
  height: 0;
  z-index: 100;
}
.drop-down-mobile .body.expanded {
  visibility: visible;
  height: fit-content;
}

.drop-down-web .icon-style {
  @apply bg-[#f8f8f8] group-hover:opacity-100 flex items-center opacity-70 justify-center w-9 h-9 mr-4 rounded-md;
}
