.custom-input {
  @apply border h-10 rounded-lg text-base border-primary-300 text-primary-700 dark:text-primary-100;
}

.custom-text-input {
  @apply border rounded-lg text-base border-primary-300 text-primary-700 dark:text-primary-100;
}

.btnClass {
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
}

.btn-normal {
  @apply bg-secondary-200 text-primary-800 dark:bg-primary-700 dark:text-primary-100;
}
.btn-success {
  @apply bg-secondary-700 text-primary-50;
}

.btn-danger {
  @apply bg-[red] text-primary-50;
}
