.device-logs thead > tr > th {
  padding: 0 10px;
}
.device-logs tr:nth-child(2n) {
  @apply bg-primary-100 dark:bg-primary-700;
}

.device-logs .img-wrapper {
  width: 100%;
  height: 100px;
  position: relative;
}
.device-logs .img-wrapper img {
  width: 100%;
  height: auto;
}
