.layout .main-section {
  @apply min-h-screen w-full relative block lg:flex bg-primary-50  dark:bg-dark-dark text-primary-800 dark:text-primary-200;
}

.layout .main-section .left-part {
  width: 250px;
  position: absolute;
  transition: all 0.2s ease-in-out;
  @apply z-20;
}
.layout .main-section .right-part {
  width: 100%;
  transition: all 0.2s ease-in-out;
  @apply z-10 p-4;
}

@media screen and (min-width: 1024px) {
  .layout .main-section {
    display: flex;
  }
  .layout .main-section .left-part {
    width: 250px;
    position: relative;
    left: 0;
  }
  .layout .main-section .right-part {
    width: calc(100% - 250px);
  }
}
