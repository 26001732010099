.render-tab {
}

.render-tab .labels-wrapper {
  display: flex;
  align-items: center;
  height: 36px;
  position: relative;
  @apply border-y border-solid border-primary-200 dark:border-primary-600;
}
.render-tab .labels-wrapper .label {
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0px 16px;
  cursor: pointer;
}
.render-tab .labels-wrapper .active-class {
  border-bottom: 2px solid;
  margin-top: 2px;
  @apply text-secondary-600;
}
.render-tab .tab-body li {
  /* text-align: justify; */
}

.sub-tab-body .expand-body {
  display: none;
  margin-left: 24px;
  height: 0;
}

.sub-tab-body .expand + .expand-body {
  display: block;
  height: auto;
}
.render-tab svg {
  width: inherit;
  height: inherit;
}
