.login-form .custom-input {
  height: 2.5rem;
  border-radius: 0.5rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(212 212 212 / var(--tw-border-opacity));
  font-size: 1rem;
  line-height: 1.5rem;
  --tw-text-opacity: 1;
  color: black !important;
}
